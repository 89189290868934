<template>
    <CRow>
        <CCol sm="12">           
            <ListForm :headerTitle="$t('pages.shares.shareholdersListing')" :onSearch="onSearch">
                <template v-slot:headerLeft>
                    <button type="button" name="add-shareholder" class="btn btn-success" @click="onAddNewShareholder">
                        {{ $t('pages.shares.addShareholder') }}
                    </button>
                </template>

                <template v-slot:searchBox>
                    <CForm>
                        <CRow>
                            <CCol md="6">
                                <CInput :label="$t('pages.shares.nameOfPersonOrOrganization')" v-model="searchInfo.personOrOrganization" horizontal></CInput>
                            </CCol>
                            <CCol md="6">
                                <CInput :label="$t('common.phone')" v-model="searchInfo.phone" horizontal></CInput>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol md="6">
                                <CInput :label="$t('common.email')" v-model="searchInfo.email" horizontal></CInput>
                            </CCol>
                            <CCol md="6">
                                
                            </CCol>
                        </CRow>
                    </CForm>
                </template>
                <template v-slot:list>
                    <CDataTable :items="shareholderList"
                                :fields="fields"
                                :noItemsView="{ noItems: $t('common.nodatafound') }"
                                hover
                                striped
                                border
                                small
                                fixed
                                @page-change="onPageChanged"
                                :loading="isLoading"
                                :items-per-page="itemsPerPage">
                        <template #numOfShares_text="{item}">
                            <td class="py-2">
                                <label v-text="$func.formatNumber(item.numOfShares)" />
                            </td>
                        </template>
                        <template #amountInvested_text="{item}">
                            <td class="py-2">
                                <label v-text="$func.formatNumber(item.amountInvested)" />
                            </td>
                        </template>
                        <template #shareholder_detail_actions="{item, index}">
                            <td class="py-2">
                                <CButton color="primary"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onViewShareholder(item, index)">
                                    {{ $t('common.detail') }}
                                </CButton>
                                <CButton class="btn btn-danger"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onDeleteShareholder(item, index)">
                                    {{ $t('common.remove') }}
                                </CButton>
                            </td>
                        </template>
                    </CDataTable>
                    <CPagination :pages="totalPages"
                                 :activePage="pageIndex + 1"
                                 @update:activePage="onPageChanged"></CPagination>
                </template>
            </ListForm>
            <Confirmation ref="confirmation"></Confirmation>
        </CCol>
    </CRow>
</template>

<script>
    import { mapActions,  mapState } from 'vuex'
    import i18n from '@/plugins/i18n'
    import Confirmation from '@/components/Confirmation.vue'
    import ListForm from '@/components/ListForm.vue' 

    export default {
        name: 'ShareholdersList',
        data() {
            return {              
                itemsPerPage: 10,
                warningModal: false,
                fields: [                   
                    { key: 'personOrOrganization', label: i18n.t('common.name') },
                    { key: 'amountInvested_text', label: i18n.t('pages.shares.amountInvested') },
                    { key: 'numOfShares_text', label: i18n.t('pages.shares.numberOfShares') },
                    { key: 'phone', label: i18n.t('common.phone') },
                    { key: 'email', label: i18n.t('common.email') },
                    //{ key: 'createdDate', label: i18n.t('common.address') },
                    //{ key: 'deposit_status', label: i18n.t('common.status') },
                    //{ key: 'status', label: i18n.t('common.status') },
                    {
                        key: 'shareholder_detail_actions',
                        label: i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
                ]
            };
        },
        components: {
            ListForm,
            Confirmation
        },
        computed: {          
            ...mapState('shareholder', ['searchInfo', 'isLoading', 'shareholderList', 'totalPages', 'pageIndex'])
        },
        methods: {
            ...mapActions('shareholder', ['searchShareholders']),
                                  
            onSearch() {
                this.searchShareholders();
            },
            onPageChanged(pageNo, reduced) {
                console.log('PageChanged', { pageNo, reduced });
                this.searchShareholders(pageNo);
            },
            onAddNewShareholder() {
                this.$router.push('/shareholders/add');
            },
            onViewShareholder(item) {
                this.$router.push('/shareholders/detail/' + item.id);
            },
            async onDeleteShareholder(item) {
                this.$refs.confirmation.show(i18n.t('pages.shares.confirmDeleteShareholder'), async () => {                    
                    var retVal = await this.$store.dispatch("shareholder/deleteShareholder", item.id);
                    if (retVal == true) {
                        this.searchShareholders();
                    }
                });
            },
        },
        created() {         
            this.searchShareholders();
        }
    }
</script>