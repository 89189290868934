var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('ListForm',{attrs:{"headerTitle":_vm.$t('pages.shares.shareholdersListing'),"onSearch":_vm.onSearch},scopedSlots:_vm._u([{key:"headerLeft",fn:function(){return [_c('button',{staticClass:"btn btn-success",attrs:{"type":"button","name":"add-shareholder"},on:{"click":_vm.onAddNewShareholder}},[_vm._v(" "+_vm._s(_vm.$t('pages.shares.addShareholder'))+" ")])]},proxy:true},{key:"searchBox",fn:function(){return [_c('CForm',[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"label":_vm.$t('pages.shares.nameOfPersonOrOrganization'),"horizontal":""},model:{value:(_vm.searchInfo.personOrOrganization),callback:function ($$v) {_vm.$set(_vm.searchInfo, "personOrOrganization", $$v)},expression:"searchInfo.personOrOrganization"}})],1),_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"label":_vm.$t('common.phone'),"horizontal":""},model:{value:(_vm.searchInfo.phone),callback:function ($$v) {_vm.$set(_vm.searchInfo, "phone", $$v)},expression:"searchInfo.phone"}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"label":_vm.$t('common.email'),"horizontal":""},model:{value:(_vm.searchInfo.email),callback:function ($$v) {_vm.$set(_vm.searchInfo, "email", $$v)},expression:"searchInfo.email"}})],1),_c('CCol',{attrs:{"md":"6"}})],1)],1)]},proxy:true},{key:"list",fn:function(){return [_c('CDataTable',{attrs:{"items":_vm.shareholderList,"fields":_vm.fields,"noItemsView":{ noItems: _vm.$t('common.nodatafound') },"hover":"","striped":"","border":"","small":"","fixed":"","loading":_vm.isLoading,"items-per-page":_vm.itemsPerPage},on:{"page-change":_vm.onPageChanged},scopedSlots:_vm._u([{key:"numOfShares_text",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('label',{domProps:{"textContent":_vm._s(_vm.$func.formatNumber(item.numOfShares))}})])]}},{key:"amountInvested_text",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('label',{domProps:{"textContent":_vm._s(_vm.$func.formatNumber(item.amountInvested))}})])]}},{key:"shareholder_detail_actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.onViewShareholder(item, index)}}},[_vm._v(" "+_vm._s(_vm.$t('common.detail'))+" ")]),_c('CButton',{staticClass:"btn btn-danger",attrs:{"variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.onDeleteShareholder(item, index)}}},[_vm._v(" "+_vm._s(_vm.$t('common.remove'))+" ")])],1)]}}])}),_c('CPagination',{attrs:{"pages":_vm.totalPages,"activePage":_vm.pageIndex + 1},on:{"update:activePage":_vm.onPageChanged}})]},proxy:true}])}),_c('Confirmation',{ref:"confirmation"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }